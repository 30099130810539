.App {
  margin: auto;
  width: 100%;
}

#root {
  background-color: #3F3E3E;
}

.Header {
  
}

.panel-body {
  
}

.panel-1 {

}

.panel-2 {

}

.panel-3 {

}