@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Karla&display=swap');

.navbar {
    position: fixed;
    z-index: 30;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    color: black;
    box-shadow: -1px 1px 15px 0px rgba(0,0,0,0.75);
}

.brand-title {
    font-size: 1.5rem;
    margin:.5rem;
    display: flex;
    flex-direction: row;
}

.brand-title div {
    width: 4px;
    background-color: black;
    margin-left: 20px;
    margin-right: 25px;
}

.navbar-links ul {
    margin: 0;
    padding: 0;
    display: flex;
}

.navbar-links li {
    list-style: none;
}

.navbar-links li a {
    text-decoration: none;
    padding:  1rem;
    display: block;
    color: #3F3E3E;
    font-family: 'Rubik', sans-serif;
}

.navbar-links li:hover {
    background-color: #FF8C00;
}

.toggle-button {
    position: absolute;
    top: .75rem;
    right: 1rem;
    display: none;
    flex-direction: column;
    justify-content: space-between;
    width: 30px;
    height: 20px;
    
}

.toggle-button .bar {
    height: 3px;
    width: 100%;
    background-color: #3F3E3E;
    border-radius: 10px;
}

@media (max-width: 600px) {
    .toggle-button{
        display: flex;
        margin-top: 10px;
    }

    .navbar-links {
        display: none;
        width: 100%;
    }

    .navbar{
        flex-direction: column;
        align-items: flex-start;
    }

    .navbar-links ul {
        width: 100%;
        flex-direction: column;
    }

    .navbar-links li {
        text-align: center;
    }

    .navbar-links li a {
        padding: .5rem 1rem;
    }

    .navbar-links.active {
        display: flex;
    }
}