@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Karla&display=swap');

.sub-panel-1-we-background {
    margin-bottom: 20px;
    width: 170px;
    background-color: white;
    display: inline-block;
}

.sub-panel-1-we-highlight {
    background-color: #FF8C00;
    box-shadow: 0px -2px 12px 2px rgba(0,0,0,0.38);
    line-height: 40px;
    height: 50px;
}

.sub-panel-1-we {
    font-family: 'Rubik', sans-serif;
    color: white;
    font-size: 80px;
    margin-left: 30px;
    text-shadow: 1px -1px 4px #666;
}

.sub-panel-1-encourage-background {
    margin-bottom: 20px;
    width: 480px;
    display: inline-block;
    
}

.sub-panel-1-encourage-highlight {
    background-color: #FF8C00;
    box-shadow: 0px -2px 12px 2px rgba(0,0,0,0.38);
    line-height: 40px;
    height: 50px;
}

.sub-panel-1-encourage {
    font-family: 'Rubik', sans-serif;
    color: white;
    font-size: 80px;
    margin-left: 30px;
    text-shadow: 1px -1px 4px #666;
}

.sub-panel-1-change-background {
    margin-bottom: 10px;
    width: 370px;
    display: inline-block;
}

.sub-panel-1-change-highlight {
    background-color: #FF8C00;
    box-shadow: 0px -2px 12px 2px rgba(0,0,0,0.38);
    line-height: 40px;
    height: 50px;
}

.sub-panel-1-change {
    font-family: 'Rubik', sans-serif;
    color: white;
    font-size: 80px;
    margin-left: 30px;
    text-shadow: 1px -1px 4px #666;
}

.sub-panel-1-background {
    
}

.sub-panel-1-about {
    width: 30%;
    margin-left: 30px;
    text-align: left;
    font-family: 'Karla', sans-serif;
    font-size: 20px;
}

.sub-panel-3 {

}

.sub-panel-1 {
    position: absolute;
    margin-top: 250px;
    background-color: white;
    background-image: linear-gradient(to bottom right, white, transparent 50%, transparent 50%, transparent);
    margin-left: 100px;
}

.container-panel-1 {
    position: relative;
    background-image: url('./women.jpg');
    background-size: cover;
    background-position: left 450px bottom 0;
    width: auto;
    margin: 0 auto;
    height: 800px;
}

.container-group {
    padding: 20px;  
    width: 60%;
    background: white;
    -webkit-clip-path: polygon(0 0, 100% 0%, 80% 100%, 0% 100%);
    clip-path: polygon(0 0, 100% 0%, 80% 100%, 0% 100%);
    height: 760px;
}

@media screen and (max-width: 900px) {
    .sub-panel-1 {
        margin-left: 0px;
        margin-top: 100px;
        background-image: none;
        display: flex;
        flex-direction: column;
        
        align-items: center;
    }

    .sub-panel-1-we-background {
        margin-bottom: 10px;
        width: 75px;
        background-color: white;
        display: inline-block;
        align-items: center;
    }
    
    .sub-panel-1-we-highlight {
        background-color: #FF8C00;
        box-shadow: 0px -2px 12px 2px rgba(0,0,0,0.38);
        line-height: 20px;
        height: 25px;
    }
    
    .sub-panel-1-we {
        font-family: 'Rubik', sans-serif;
        color: white;
        font-size: 40px;
        margin-left: 10px;
        text-shadow: 1px -1px 4px #666;
    }

    .sub-panel-1-encourage-background {
        margin-bottom: 10px;
        width: 240px;
        display: inline-block;
        
    }
    
    .sub-panel-1-encourage-highlight {
        background-color: #FF8C00;
        box-shadow: 0px -2px 12px 2px rgba(0,0,0,0.38);
        line-height: 20px;
        height: 25px;
    }
    
    .sub-panel-1-encourage {
        font-family: 'Rubik', sans-serif;
        color: white;
        font-size: 40px;
        margin-left: 10px;
        text-shadow: 1px -1px 4px #666;
    }

    .sub-panel-1-change-background {
        margin-bottom: 10px;
        width: 180px;
        display: inline-block;

    }
    
    .sub-panel-1-change-highlight {
        background-color: #FF8C00;
        box-shadow: 0px -2px 12px 2px rgba(0,0,0,0.38);
        line-height: 20px;
        height: 25px;
    }
    
    .sub-panel-1-change {
        font-family: 'Rubik', sans-serif;
        color: white;
        font-size: 40px;
        margin-left: 10px;
        text-shadow: 1px -1px 4px #666;
    }

    .sub-panel-1-background {
        padding: 1em 2em;
    }

    .sub-panel-1-about {
        margin-top: 20px;
        text-align: center;
        font-size: large;
        margin-left: 0px;
        width: 100%;

    }

    .container-panel-1 {
        position: relative;
        background-color: white;
        width: auto;
        margin: 0 auto;
        height: 500px;
        background-image: none;
    }

    .container-group {
        padding: 0px;  
        width: 100%;
        background: white;
        -webkit-clip-path: polygon(0 0, 100% 0%, 80% 100%, 0% 100%);
        clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
        height: 500px;

    }
}