
.panel-square-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 auto;
    max-width: 1500px;
}

.square-container {
    display: flex;
    width: 25%;
}

.square-base {
    padding: 0.15em;
}

.square-image {
    width: 100%;
    height: auto;
    display: block;
}

.square-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    font-weight: bold;
}
.square-type-creative .square-footer {
    background-color:  #53CF3C;
}
.square-type-development .square-footer {
    background-color:  #FF8C00;
}
.square-name {
    font-size: 1.2em;
}
.square-position {
    font-size: 0.9em;
}


@media screen and (max-width: 900px) {
    .panel-square-list {
        flex-direction: column;
        align-items: center;
    }
    .square-container {
        width: 80%;
    }
    
}



/* .panel-5 {
    background-color: white;
    width: 100%;
}

.square-creative-highlight {
    background-color: #53CF3C;
    z-index: 10;
    width: 280px;
}

.staff-image {
    width: 100%;
    height: auto;
}

.creative {
    width: 150px;
    height: 150px;
    margin-top: 60px;
}

.square-3 {
    background-color: white;
    width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.square-3-development {
    width: 300px;
    height: 300px;
}

.square-3-creative{
    width: 300px;
    height: 300px;
}

.square-base {
    background-size: cover;
    width: 500px;
    display: flex;
    flex-direction: column;
}

.square-development-highlight {
    background-color: #FF8C00;
    z-index: 10;
    width: 100%;
}


@media screen and (max-width: 900px) {
    .square-3-development {
        width: 150px;
        height: 150px;
        margin: 0px 0px 0px 0px;
    }
    
    .square-3-creative{
        width: 150px;
        height: 150px;
        margin: 0px 0px 0px 0px;
    }

    .square-3 {
        width: 150px;
    }

    .staff-image {
        width: 150px;
        height: 150px;
    }

    .square-creative-highlight, .square-development-highlight {
        width: 130px;
    } 

    .creative {
        width: 75px;
        height: 75px;
        margin-top: 30px;
    }
} */