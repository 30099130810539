.panel-3-container {
    background-image: url('./texture.jpg');
    background-position: center;
    height: auto;
    background-size: cover;
}

.circle-container {
    display: flex;
    flex-direction: 'row';
    justify-content: center;
    padding: 40px;
    text-align: center;
    font-family: 'Rubik', 'sans-sarif';
}

.circle{
    height: 200px;
    width: 200px;
    background-color: white;
    border-radius: 50%;
    margin-right: 30px;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.circle-logo {
    width: 100px;
    height: 100px;

    margin-top: 30px;
}

.circle-text {
    font-size: medium;
    margin-top: 5px;
    width: 50%;
}

@media screen and (max-width: 900px) {
    .circle-container {
        width: 100%;
        display: flex;
        flex-direction: 'row';
        flex-wrap: wrap;
        justify-content: center;
        padding: 5px;
        text-align: center;
        font-family: 'Rubik', 'sans-sarif';
    }
    
    .circle{
        height: 125px;
        width: 125px;
        background-color: white;
        border-radius: 50%;
        margin: 10px 30px 20px 0px;
        display: inline-block
    }
    
    .circle-logo {
        width: 40px;
        height: 40px;
    }
    
    .circle-text {
        font-size: 12px;
        margin-top: 5px;
        margin: auto;
        width: 60%;
    }
}