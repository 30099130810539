.txt-panel-container {
    background-color: white;
    padding: 120px;
}

.txt-panel-header {
    font-family: 'Rubik', sans-serif;
    font-size: 50px;
    text-align: center;
    margin-bottom: 30px;
    color: #3F3E3E;
}

.txt-panel-body {
    font-size: 20px;
    font-family: 'Karla', sans-serif;
    width: 50%;
    text-align: center;
    margin: auto;
}

@media screen and (max-width: 900px) {
    .txt-panel-container {
        background-color: white;
        padding: 40px;
    }
    
    .txt-panel-header {
        font-family: 'Rubik', sans-serif;
        font-size: 30px;
        text-align: center;
        margin-bottom: 30px;
        color: #3F3E3E;
    }
    
    .txt-panel-body {
        font-size: 18px;
        font-family: 'Karla', sans-serif;
        width: 100%;
        text-align: center;
        margin: auto;
    }
}