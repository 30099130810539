.panelfooter-container {
    background-color: #403F3F;
}

.bar-1 {
    height: 60px;
}

.bar-2 {
    height: 5px;
    background-color: white;
}

.bar-3 {
    color: white;
    margin-left: 100px;
    margin-top: 5px;
}

.bar-4 {
    height: 40px;
}

@media screen and (max-width: 900px) {
    .bar-3 {
        margin-left: 10px;
        margin-top: 5px;
        font-size: small;
    }
}