@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Karla&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Karla&display=swap);
* { margin: 0; padding: 0 ;}

html, body, #root {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  margin: auto;
  width: 100%;
}

#root {
  background-color: #3F3E3E;
}

.Header {
  
}

.panel-body {
  
}

.panel-1 {

}

.panel-2 {

}

.panel-3 {

}
.txt-panel-container {
    background-color: white;
    padding: 120px;
}

.txt-panel-header {
    font-family: 'Rubik', sans-serif;
    font-size: 50px;
    text-align: center;
    margin-bottom: 30px;
    color: #3F3E3E;
}

.txt-panel-body {
    font-size: 20px;
    font-family: 'Karla', sans-serif;
    width: 50%;
    text-align: center;
    margin: auto;
}

@media screen and (max-width: 900px) {
    .txt-panel-container {
        background-color: white;
        padding: 40px;
    }
    
    .txt-panel-header {
        font-family: 'Rubik', sans-serif;
        font-size: 30px;
        text-align: center;
        margin-bottom: 30px;
        color: #3F3E3E;
    }
    
    .txt-panel-body {
        font-size: 18px;
        font-family: 'Karla', sans-serif;
        width: 100%;
        text-align: center;
        margin: auto;
    }
}
.panelfooter-container {
    background-color: #403F3F;
}

.bar-1 {
    height: 60px;
}

.bar-2 {
    height: 5px;
    background-color: white;
}

.bar-3 {
    color: white;
    margin-left: 100px;
    margin-top: 5px;
}

.bar-4 {
    height: 40px;
}

@media screen and (max-width: 900px) {
    .bar-3 {
        margin-left: 10px;
        margin-top: 5px;
        font-size: small;
    }
}
.navbar {
    position: fixed;
    z-index: 30;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    color: black;
    box-shadow: -1px 1px 15px 0px rgba(0,0,0,0.75);
}

.brand-title {
    font-size: 1.5rem;
    margin:.5rem;
    display: flex;
    flex-direction: row;
}

.brand-title div {
    width: 4px;
    background-color: black;
    margin-left: 20px;
    margin-right: 25px;
}

.navbar-links ul {
    margin: 0;
    padding: 0;
    display: flex;
}

.navbar-links li {
    list-style: none;
}

.navbar-links li a {
    text-decoration: none;
    padding:  1rem;
    display: block;
    color: #3F3E3E;
    font-family: 'Rubik', sans-serif;
}

.navbar-links li:hover {
    background-color: #FF8C00;
}

.toggle-button {
    position: absolute;
    top: .75rem;
    right: 1rem;
    display: none;
    flex-direction: column;
    justify-content: space-between;
    width: 30px;
    height: 20px;
    
}

.toggle-button .bar {
    height: 3px;
    width: 100%;
    background-color: #3F3E3E;
    border-radius: 10px;
}

@media (max-width: 600px) {
    .toggle-button{
        display: flex;
        margin-top: 10px;
    }

    .navbar-links {
        display: none;
        width: 100%;
    }

    .navbar{
        flex-direction: column;
        align-items: flex-start;
    }

    .navbar-links ul {
        width: 100%;
        flex-direction: column;
    }

    .navbar-links li {
        text-align: center;
    }

    .navbar-links li a {
        padding: .5rem 1rem;
    }

    .navbar-links.active {
        display: flex;
    }
}
.sub-panel-1-we-background {
    margin-bottom: 20px;
    width: 170px;
    background-color: white;
    display: inline-block;
}

.sub-panel-1-we-highlight {
    background-color: #FF8C00;
    box-shadow: 0px -2px 12px 2px rgba(0,0,0,0.38);
    line-height: 40px;
    height: 50px;
}

.sub-panel-1-we {
    font-family: 'Rubik', sans-serif;
    color: white;
    font-size: 80px;
    margin-left: 30px;
    text-shadow: 1px -1px 4px #666;
}

.sub-panel-1-encourage-background {
    margin-bottom: 20px;
    width: 480px;
    display: inline-block;
    
}

.sub-panel-1-encourage-highlight {
    background-color: #FF8C00;
    box-shadow: 0px -2px 12px 2px rgba(0,0,0,0.38);
    line-height: 40px;
    height: 50px;
}

.sub-panel-1-encourage {
    font-family: 'Rubik', sans-serif;
    color: white;
    font-size: 80px;
    margin-left: 30px;
    text-shadow: 1px -1px 4px #666;
}

.sub-panel-1-change-background {
    margin-bottom: 10px;
    width: 370px;
    display: inline-block;
}

.sub-panel-1-change-highlight {
    background-color: #FF8C00;
    box-shadow: 0px -2px 12px 2px rgba(0,0,0,0.38);
    line-height: 40px;
    height: 50px;
}

.sub-panel-1-change {
    font-family: 'Rubik', sans-serif;
    color: white;
    font-size: 80px;
    margin-left: 30px;
    text-shadow: 1px -1px 4px #666;
}

.sub-panel-1-background {
    
}

.sub-panel-1-about {
    width: 30%;
    margin-left: 30px;
    text-align: left;
    font-family: 'Karla', sans-serif;
    font-size: 20px;
}

.sub-panel-3 {

}

.sub-panel-1 {
    position: absolute;
    margin-top: 250px;
    background-color: white;
    background-image: linear-gradient(to bottom right, white, transparent 50%, transparent 50%, transparent);
    margin-left: 100px;
}

.container-panel-1 {
    position: relative;
    background-image: url(/static/media/women.b55d60a5.jpg);
    background-size: cover;
    background-position: left 450px bottom 0;
    width: auto;
    margin: 0 auto;
    height: 800px;
}

.container-group {
    padding: 20px;  
    width: 60%;
    background: white;
    -webkit-clip-path: polygon(0 0, 100% 0%, 80% 100%, 0% 100%);
    clip-path: polygon(0 0, 100% 0%, 80% 100%, 0% 100%);
    height: 760px;
}

@media screen and (max-width: 900px) {
    .sub-panel-1 {
        margin-left: 0px;
        margin-top: 100px;
        background-image: none;
        display: flex;
        flex-direction: column;
        
        align-items: center;
    }

    .sub-panel-1-we-background {
        margin-bottom: 10px;
        width: 75px;
        background-color: white;
        display: inline-block;
        align-items: center;
    }
    
    .sub-panel-1-we-highlight {
        background-color: #FF8C00;
        box-shadow: 0px -2px 12px 2px rgba(0,0,0,0.38);
        line-height: 20px;
        height: 25px;
    }
    
    .sub-panel-1-we {
        font-family: 'Rubik', sans-serif;
        color: white;
        font-size: 40px;
        margin-left: 10px;
        text-shadow: 1px -1px 4px #666;
    }

    .sub-panel-1-encourage-background {
        margin-bottom: 10px;
        width: 240px;
        display: inline-block;
        
    }
    
    .sub-panel-1-encourage-highlight {
        background-color: #FF8C00;
        box-shadow: 0px -2px 12px 2px rgba(0,0,0,0.38);
        line-height: 20px;
        height: 25px;
    }
    
    .sub-panel-1-encourage {
        font-family: 'Rubik', sans-serif;
        color: white;
        font-size: 40px;
        margin-left: 10px;
        text-shadow: 1px -1px 4px #666;
    }

    .sub-panel-1-change-background {
        margin-bottom: 10px;
        width: 180px;
        display: inline-block;

    }
    
    .sub-panel-1-change-highlight {
        background-color: #FF8C00;
        box-shadow: 0px -2px 12px 2px rgba(0,0,0,0.38);
        line-height: 20px;
        height: 25px;
    }
    
    .sub-panel-1-change {
        font-family: 'Rubik', sans-serif;
        color: white;
        font-size: 40px;
        margin-left: 10px;
        text-shadow: 1px -1px 4px #666;
    }

    .sub-panel-1-background {
        padding: 1em 2em;
    }

    .sub-panel-1-about {
        margin-top: 20px;
        text-align: center;
        font-size: large;
        margin-left: 0px;
        width: 100%;

    }

    .container-panel-1 {
        position: relative;
        background-color: white;
        width: auto;
        margin: 0 auto;
        height: 500px;
        background-image: none;
    }

    .container-group {
        padding: 0px;  
        width: 100%;
        background: white;
        -webkit-clip-path: polygon(0 0, 100% 0%, 80% 100%, 0% 100%);
        clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
        height: 500px;

    }
}
.panel-3-container {
    background-image: url(/static/media/texture.cf540250.jpg);
    background-position: center;
    height: auto;
    background-size: cover;
}

.circle-container {
    display: flex;
    flex-direction: 'row';
    justify-content: center;
    padding: 40px;
    text-align: center;
    font-family: 'Rubik', 'sans-sarif';
}

.circle{
    height: 200px;
    width: 200px;
    background-color: white;
    border-radius: 50%;
    margin-right: 30px;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.circle-logo {
    width: 100px;
    height: 100px;

    margin-top: 30px;
}

.circle-text {
    font-size: medium;
    margin-top: 5px;
    width: 50%;
}

@media screen and (max-width: 900px) {
    .circle-container {
        width: 100%;
        display: flex;
        flex-direction: 'row';
        flex-wrap: wrap;
        justify-content: center;
        padding: 5px;
        text-align: center;
        font-family: 'Rubik', 'sans-sarif';
    }
    
    .circle{
        height: 125px;
        width: 125px;
        background-color: white;
        border-radius: 50%;
        margin: 10px 30px 20px 0px;
        display: inline-block
    }
    
    .circle-logo {
        width: 40px;
        height: 40px;
    }
    
    .circle-text {
        font-size: 12px;
        margin-top: 5px;
        margin: auto;
        width: 60%;
    }
}

.panel-square-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 auto;
    max-width: 1500px;
}

.square-container {
    display: flex;
    width: 25%;
}

.square-base {
    padding: 0.15em;
}

.square-image {
    width: 100%;
    height: auto;
    display: block;
}

.square-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    font-weight: bold;
}
.square-type-creative .square-footer {
    background-color:  #53CF3C;
}
.square-type-development .square-footer {
    background-color:  #FF8C00;
}
.square-name {
    font-size: 1.2em;
}
.square-position {
    font-size: 0.9em;
}


@media screen and (max-width: 900px) {
    .panel-square-list {
        flex-direction: column;
        align-items: center;
    }
    .square-container {
        width: 80%;
    }
    
}



/* .panel-5 {
    background-color: white;
    width: 100%;
}

.square-creative-highlight {
    background-color: #53CF3C;
    z-index: 10;
    width: 280px;
}

.staff-image {
    width: 100%;
    height: auto;
}

.creative {
    width: 150px;
    height: 150px;
    margin-top: 60px;
}

.square-3 {
    background-color: white;
    width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.square-3-development {
    width: 300px;
    height: 300px;
}

.square-3-creative{
    width: 300px;
    height: 300px;
}

.square-base {
    background-size: cover;
    width: 500px;
    display: flex;
    flex-direction: column;
}

.square-development-highlight {
    background-color: #FF8C00;
    z-index: 10;
    width: 100%;
}


@media screen and (max-width: 900px) {
    .square-3-development {
        width: 150px;
        height: 150px;
        margin: 0px 0px 0px 0px;
    }
    
    .square-3-creative{
        width: 150px;
        height: 150px;
        margin: 0px 0px 0px 0px;
    }

    .square-3 {
        width: 150px;
    }

    .staff-image {
        width: 150px;
        height: 150px;
    }

    .square-creative-highlight, .square-development-highlight {
        width: 130px;
    } 

    .creative {
        width: 75px;
        height: 75px;
        margin-top: 30px;
    }
} */
.contact-us-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Karla', sans-serif;
}

.contact-header {
    font-size: 50px;
    font-family: 'Rubik', sans-serif;
    color: #3F3E3E;
    margin-bottom: 30px;
}
.sub-header {
    font-size: 20px;
}

.phone-container, .email-container {
    font-size: 18px;
    padding: 10px;
}

.phone-container {
    margin-bottom: 20px;
}

.phonenumber, .email {
    margin-bottom: 10px;
    font-family: 'Rubik', sans-serif;
}

.contact-body {
    display: flex;
    flex-direction: row;
    font-size: 20px;
    padding: 20px;
    margin-top: 30px;
    margin-bottom: 20px;
}

.contact-form {
    padding: 10px;
    margin-left: 50px;
    
}

.contact-form input {
    width: 150px;
    margin-bottom: 20px;
    padding: 0.5rem;
}

.contact-form input:not(:first-child){
    margin-left: 10px;
}

.message-area {
    display: flex;
    flex-direction: column;
}

.message-area textarea {
    padding: 0.5rem;
    margin-bottom: 20px;
}

.message-area button {
    border-radius:4px;
    background-color: #FF8C00;
	border:1px solid #cc7000;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-size:17px;
	padding:16px 31px;
	text-decoration:none;
	text-shadow:0px 1px 0px #cc7000;
}

.message-area button:hover {
    background-color: #53CF3C;
    border: 1px solid #43b82e;
    text-shadow: 0px 1px 0px #43b82e;
}

@media screen and (max-width: 900px) {
    .contact-body {
        flex-direction: column;
        padding: 0px;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .contact-header {
        font-size: 30px;
    }

    .sub-header {
        font-size: 18px;
        text-align: center;
    }

    .contact-form {
        margin-left: 0px;
        padding: 20px;
    }

    .contact-form input {
        width: 100%;
        padding: 0px;
        height: 30px;
    }

    .contact-form input:not(:first-child){
        margin-left: 0px;
    }

    .message-area{
        display: block;
        padding: 0px;
        width: 100%;
        
    }

    .message-area textarea {
        padding: 0px;
    }

    .riverline-info {
        margin-bottom: 30px;
    }

    .message-area textarea, .message-area button {
        width: 100%;
    }

    .message-area button {
        display: block;
    }
}
