.contact-us-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Karla', sans-serif;
}

.contact-header {
    font-size: 50px;
    font-family: 'Rubik', sans-serif;
    color: #3F3E3E;
    margin-bottom: 30px;
}
.sub-header {
    font-size: 20px;
}

.phone-container, .email-container {
    font-size: 18px;
    padding: 10px;
}

.phone-container {
    margin-bottom: 20px;
}

.phonenumber, .email {
    margin-bottom: 10px;
    font-family: 'Rubik', sans-serif;
}

.contact-body {
    display: flex;
    flex-direction: row;
    font-size: 20px;
    padding: 20px;
    margin-top: 30px;
    margin-bottom: 20px;
}

.contact-form {
    padding: 10px;
    margin-left: 50px;
    
}

.contact-form input {
    width: 150px;
    margin-bottom: 20px;
    padding: 0.5rem;
}

.contact-form input:not(:first-child){
    margin-left: 10px;
}

.message-area {
    display: flex;
    flex-direction: column;
}

.message-area textarea {
    padding: 0.5rem;
    margin-bottom: 20px;
}

.message-area button {
    border-radius:4px;
    background-color: #FF8C00;
	border:1px solid #cc7000;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-size:17px;
	padding:16px 31px;
	text-decoration:none;
	text-shadow:0px 1px 0px #cc7000;
}

.message-area button:hover {
    background-color: #53CF3C;
    border: 1px solid #43b82e;
    text-shadow: 0px 1px 0px #43b82e;
}

@media screen and (max-width: 900px) {
    .contact-body {
        flex-direction: column;
        padding: 0px;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .contact-header {
        font-size: 30px;
    }

    .sub-header {
        font-size: 18px;
        text-align: center;
    }

    .contact-form {
        margin-left: 0px;
        padding: 20px;
    }

    .contact-form input {
        width: 100%;
        padding: 0px;
        height: 30px;
    }

    .contact-form input:not(:first-child){
        margin-left: 0px;
    }

    .message-area{
        display: block;
        padding: 0px;
        width: 100%;
        
    }

    .message-area textarea {
        padding: 0px;
    }

    .riverline-info {
        margin-bottom: 30px;
    }

    .message-area textarea, .message-area button {
        width: 100%;
    }

    .message-area button {
        display: block;
    }
}